<template>
    <div class="activity-container">
      <h2 class="active-title">{{userInfo.name}} 2020-2021学年下学期综合评价报告</h2>
      <p class="active-desc"><span class="blue">{{userInfo.name}}</span> ，你好！经过一学期的学习，你一定有很多收获吧，快来看看智慧研修平台为你的学习表现带来的全方位解读吧！在这里，你将清晰了解到自己在哪些学习内容上表现优秀，哪些内容上还需要加强提升。希望专业的报告能为你以后的学习指明方向，祝你取得更大的进步！</p>
      <h3>一、整体情况</h3>
      <p><span class="blue">2020-2021学年下学期，</span> 你一共学习了<span class="blue">2</span>个课程，<span class="blue">非常棒</span> ；做了<span class="blue">3</span>次测评，<span class="blue">非常棒</span>；参加了<span class="blue">1</span>次活动，<span class="blue">以后多多参加活动哦</span>。</p>
      <div class="chart-box flex-align">
        <div :id="`myChart-Custom`"
             :style="{ width: '405px', height: '451px', margin: '0 auto' }">
        </div>
        <div class="radarInfo">
          <span v-for="(item,name) in radarInfo" :key="name">{{`${nameMap[name]}:${item}`}}</span>
        </div>
      </div>
      <h3>二、课程学习情况</h3>
      <p><span>2020-2021学年下学期，</span>你一共学习了<span class="blue">2</span>个课程，涉及<span class="blue">1</span>个项目。课程学习具体情况如下表所示：</p>
      <el-table :data="tableData" border style="width: 100%">
             <el-table-column
               prop="name"
               label="课程名称"
               width="267"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="project"
               label="所属项目"
               width="265"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="attendent"
               label="出勤情况"
               width="265"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="time"
               label="观看时长"
               width="265"
               align="center"
             >
             </el-table-column>
        </el-table>
    <h3>三、测评完成情况</h3>
      <p><span class="blue">2020-2021学年下学期，</span>你一共做了<span class="blue">3</span>份测评，其中，作业做了<span class="blue">3</span>份，问卷做了<span class="blue">0</span>次，测试做了<span class="blue">0</span>份，涉及<span class="blue">1</span>个项目，测评完成具体情况如下表所示：</p>
      <div class="chart-box flex-align">
        <div :id="`myChart-Custom1`"
             :style="{ width: '405px', height: '451px', margin: '0 auto' }">
        </div>
        <div class="radarInfo">
          <h5>通用语言能力测试-后测</h5>
          <p>89.6分</p>
          <span v-for="(item,name) in radarInfo" :key="name">{{`${nameMap[name]}:${item}`}} <i class="el-icon-top" style="color:red"></i></span>
        </div>
      </div>
      <el-table :data="tableDatas" border style="width: 100%">
             <el-table-column
               prop="name"
               label="作业主题名称"
               width="267"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="work"
               label="作业名称"
               width="265"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="project"
               label="所属项目"
               width="265"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="attendent"
               label="完成情况"
               width="265"
               align="center"
             >
             </el-table-column>
        </el-table>
        <h3>四、活动参与情况</h3>
      <p><span class="blue">2020-2021学年下学期，</span>你一共参加了<span class="blue">1</span>个活动，作业做了<span class="blue">1</span>份，涉及<span class="blue">1</span>个项目。活动参与具体情况如下表所示：</p>
       <el-table :data="tableDatax" border style="width: 100%">
             <el-table-column
               prop="name"
               label="活动名称"
               width="267"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="project"
               label="所属项目"
               width="265"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="attendent"
               label="报名情况"
               width="265"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="complete"
               label="完成情况"
               width="265"
               align="center"
             >
             </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data(){
        return {
            info:{},
            // defualtImage:'this.src="'+require("@/assets/images/course/activity-cover.png")+'"',
            text:'报名',
            userInfo:{},
            tableData:[
                {name:'整本书阅读',project:'京银互动一带一路共读工程',attendent:'已出勤',time:'50分钟'},
                {name:'共读课程测试',project:'京银互动一带一路共读工程',attendent:'已出勤',time:'45分钟'},
            ],
            tableDatas:[
               {name:'测试',work:'作业一',project:'京银互动一带一路共读工程',attendent:'已完成'},
               {name:'共读课程测试',work:'作业一',project:'京银互动一带一路共读工程',attendent:'已完成'},
               {name:'同读一本书作业',work:'作业一',project:'京银互动一带一路共读工程',attendent:'已完成'}
            ],
            tableDatax:[
                {name:'诗歌朗诵大赛',project:'京银互动一带一路共读工程',attendent:'已报名',complete:'已完成'}
            ],
            radarInfo:{
              courseAttend:81.3,
              workSubmit:80,
              positivity:80.65,
              workScore: 78,
              mandarinScore: 89.6
            },
            nameMap:{
              courseAttend:'课程出勤率',
              workSubmit:'作业提交率',
              positivity:'参与积极性',
              workScore: '作业得分率',
              mandarinScore: '普通话后侧得分率'
            }
        }
    },
    methods:{
        async getActivityDetail(){
            let id = this.$route.params.id
            let resData = await this.$Api.Course.getActivityDetail(id);
            // console.log(resData);
        },
        drawRadar() {
            let myChartCustom = this.$echarts.init(
                document.getElementById(`myChart-Custom`)
            );
            myChartCustom.setOption({
                legend: {
                    data: ['能力评价分', '能力最高分']
                },
                tooltip: {
                    trigger: 'item',
                },
                radar: [{
                    splitArea: {
                        areaStyle: {
                            color: ['rgba(114, 172, 209, 0.2)',
                                'rgba(114, 172, 209, 0.4)', 'rgba(114, 172, 209, 0.6)',
                                'rgba(114, 172, 209, 0.8)', 'rgba(114, 172, 209, 1)'
                            ],
                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                            shadowBlur: 10
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.5)'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.5)'
                        }
                    }
                }, {
                    // indicator: this.abilityRaderData,
                    indicator:  [
                      {
                          name: '课程出勤率',
                      },
                      {
                          name: '作业提交率',
                      },
                      {
                          name: '参与积极性',
                      },
                      {
                          name: '作业得分率',
                      },
                      {
                          name: '普通话测后得分率',
                      },
                    ],
                    center: ['50%', '50%'],
                    radius: 120
                }],
                series: [{
                    name: '能力图谱',
                    type: 'radar',
                    radarIndex: 1,
                    data: [{
                        // value:this.abilityRaderValueList,
                        value: [80, 80, 80.65, 78, 89.6],
                        areaStyle: {
                            opacity: 0.9,
                            color: "#72ACD1"
                        }
                    },]
                }]
            })
        },
        drawRadar2() {
            let myChartCustom = this.$echarts.init(
                document.getElementById(`myChart-Custom1`)
            );
            myChartCustom.setOption({
                legend: {
                    data: ['通用语言能力测试-前测', '通用语言能力测试-后测']
                },
                tooltip: {
                    trigger: 'item',
                },
                radar: [{
                    splitArea: {
                        areaStyle: {
                            color: ['rgba(114, 172, 209, 0.2)',
                                'rgba(114, 172, 209, 0.4)', 'rgba(114, 172, 209, 0.6)',
                                'rgba(114, 172, 209, 0.8)', 'rgba(114, 172, 209, 1)'
                            ],
                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                            shadowBlur: 10
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.5)'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.5)'
                        }
                    }
                }, {
                    // indicator: this.abilityRaderData,
                    indicator:  [
                      {
                          name: '单音节字词得分率',
                      },
                      {
                          name: '多音节词语得分率',
                      },
                      {
                          name: '朗读短文得分率',
                      },
                      {
                          name: '命题说话得分率',
                      }
                    ],
                    center: ['50%', '50%'],
                    radius: 120
                }],
                series: [{
                    name: '能力图谱',
                    type: 'radar',
                    radarIndex: 1,
                    data: [{
                        // value:this.abilityRaderValueList,
                        value: [80, 80, 80.65, 89.6],
                        areaStyle: {
                            opacity: 0.7,
                            color: "#ADAFE4"
                        }
                    },
                    {
                        // value:this.abilityRaderValueList,
                        value: [20, 70, 85, 78],
                        areaStyle: {
                            opacity: 0.7,
                            color: "#FEC7D3"
                        }
                    },
                    ]
                }]
            })
        },
        async getOverAll() {
            let resData = await this.$Api.Course.getOverAll();
            // console.log(resData);
        },
    },
    mounted(){
        // this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
        this.userInfo = this.$store.state.user.userInfo
        this.drawRadar();
        this.drawRadar2();
        this.getOverAll();
    },
    activated(){
    }
}
</script>

<style lang="less">
/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
@media (min-width: 1200px) {
  .breadcrumb {
    width: 1200px;
  }
  .activity-container {
    width: 1200px;
    margin: 0 auto;
  }
}
/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
@media (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb {
    width: 950px;
  }
  .activity-container {
    width: 950px;
    margin: 0 auto;
  }
}
.activity-detail {
    margin: 0 auto;
    height: auto;
    background-color: #fafafa ;
    overflow: hidden;
    min-height: 500px;
    
    .breadcrumb {
        margin: 0 auto 31px;
        padding-top: 51px;
    }
    
    .activity-container {
        box-sizing: border-box;
        margin-bottom: 50px;
        border-radius: 10px;
        padding: 35px 68px;
        background-color:#fff;

        
        .active-title {
            font-size: 28px;
            margin-bottom: 20px;
            text-align: center;
            font-weight: bold;
        }
        .active-desc {
            padding: 25px 70px;
            background-color:#fff;
            border-radius: 10px;
        }
        .active-subtitle {
            font-size: 16px;
            color: #858585;
            margin-bottom: 10px;
            span:first-child {
                margin-right: 10px;
            }
        }
        
        .cover-box {
            margin: 20px auto;
            width: 450px;
            .cover {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }   
        }

        .blue {
            color: #2373EC;
            
        }
        
        h3 {
            font-size: 20px;
            font-weight: bold;
             margin:15px 0;
        }

        p {
            text-indent:2em;
            font-size: 18px;
            line-height: 34px;
            margin:10px 0;
        }
        .explain,.time {
            margin: 50px 0;
            font-size: 16px;
            text-align: center;
        }

        .btns {
           text-align: center;
           .btn {
               line-height: 35px;
               width: 200px;
            //    padding: 0 75px;
               border-radius: 8px;
               background-color: #2373EC;
               box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
               color: #fff;
           } 
           .btn-signUp{
               border: 1px solid #2373EC;
               color: #2373EC;
               background-color: #fff;
           }
        }
    }

    .radarInfo {
      width: 450px;
      // height: 150px;
      background-color: #F8FBFE;
      padding: 50px 25px;
      box-sizing: border-box;
      span {
        display: inline-block;
        font-size: 16px;
        min-width: 45%;
        padding: 0 10px;
        margin-bottom: 20px;
      }
      h5 {
        font-size: 20px;
        text-align: center;
      }
      p {
        text-align: center;
        text-indent: 0;
      }
    }
} 



</style>